<!--
 * @Description: 
 * @Author: Rabbiter
 * @Date: 2023-02-24 18:08:34
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import './assets/css/global.css'
export default {
  name: 'app',
}
</script>

<style>

</style>
